import { Component, OnInit,  } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit{

  userName: string = "";
  userPassword: string = ""
  userEmail: string = ""
  subscriptionUserName: Subscription | undefined;
  subscriptionUsePassword: Subscription | undefined;
  demoNameReg: string = "";

  constructor(private _dataservice: DataService){}
  
  ngOnInit(): void {
    this.subscriptionUserName = this._dataservice.currentUserName.subscribe(userName => this.userName = userName)
    this.subscriptionUsePassword = this._dataservice.currentUserPassword.subscribe(userPassword => this.userPassword = userPassword)
    this.subscriptionUsePassword = this._dataservice.currentUserEmail.subscribe(userEmail => this.userEmail = userEmail)  

    this._dataservice.demoName.subscribe(demoName => {
      this.demoNameReg = demoName;
      console.log('demoNameReg:', this.demoNameReg); 
    })
  }  
  getLoginPageUrl(): string {
    if (this.demoNameReg === "asm") {
      return "https://interactive-demos.akamaicentral.com/login?demo=asm";

    } else if (this.demoNameReg === "aap") {
      return "https://interactive-demos.akamaicentral.com/login?demo=aap";

    }  else if (this.demoNameReg === "apisec") {
      return "https://se-demo.nonamesec.com/login";

    } else if (this.demoNameReg === "cpro") {
      return "https://control.akamai.com/apps/auth/#/login";  

    }else {
      return "";
    }
  }

  shouldShowLoginLink(): boolean {
    return this.demoNameReg === "asm" || this.demoNameReg === "aap" || this.demoNameReg === "cpro" || this.demoNameReg == 'apisec';
  }

}